// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family-body: 'Open Sans', Arial, 'Helvetica Neue', sans-serif;
  --ion-font-family-header: 'montserrat_webfont', Tahoma, 'Gill Sans',
    sans-serif;

  --ion-font-family: var(--ion-font-family-body);

  /** primary **/
  --ion-color-primary: #6200ee;
  --ion-color-primary-rgb: 98, 0, 238;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #813de1;
  --ion-color-primary-tint: #9d57ff;

  /** secondary **/
  --ion-color-secondary: #ffffff;
  --ion-color-secondary-rgb: 255, 255, 255;
  --ion-color-secondary-contrast: rgb(1, 30, 96);
  --ion-color-secondary-contrast-rgb: 1, 30, 96;
  --ion-color-secondary-shade: #eeeeee;
  --ion-color-secondary-tint: #ffffff;

  /** tertiary **/
  --ion-color-tertiary: #2f4bfd;
  --ion-color-tertiary-rgb: 56, 128, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #3171e0;
  --ion-color-tertiary-tint: #4c8dff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #011e60;
  --ion-color-dark-rgb: 0, 30, 96;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #011138;
  --ion-color-dark-tint: #01246f;

  /** medium **/
  --ion-color-medium: #cccccc;
  --ion-color-medium-rgb: 204, 204, 204;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #aaaaaa;
  --ion-color-medium-tint: #eeeeee;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
