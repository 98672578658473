// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These
// styles are for the entire app and not just one component. Additionally, this
// file can hold Sass mixins, functions, and placeholder classes to be imported
// and used throughout the application.

// Label to show current environment
.env-label {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 4px solid gold;
  pointer-events: none;
}
.env-label--name {
  position: absolute;
  inset-inline-start: 0;
  bottom: 0;
  padding: 1em;
  font: 100% / 1 monospace;
  background: gold;
}
