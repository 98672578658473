@media print {
  /*
    Based on: https://github.com/h5bp/main.css/blob/main/src/_print.css
  */
  *,
  *::before,
  *::after {
    box-shadow: none !important;
    text-shadow: none !important;
  }

  /* ----------------------------------------------------------------------- */

  a,
  a:visited {
    text-decoration: underline;
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  details,
  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  summary,
  h2,
  h3 {
    page-break-after: avoid;
  }

  /* ----------------------------------------------------------------------- */

  /* Utility to explicitly hide elements */
  .no-print,
  .no-print * {
    display: none !important;
  }

  /* ----------------------------------------------------------------------- */
}
